<template>
  <div>
    <Navigation/>
    <!-- AI作画 -->
    <div class="bg_box">
      <div class="noneBox"></div>
      <div class="box">
        <p class="back" @click="$router.push('/aiNav')">返回</p>

        <div class="ct_box flex">
          <div class="form_upload_box">
            <ai_title ai_title="“智能Ai数字人-智能2D数字人"/>
            <div class="form_title_1">
              <div>
                智能2D数字人
              </div>
              <div class="versionSwitch">
                <div class="versionTab" :class="version=='1'?'versionTab_active':''" @click="changeVersion">
                  基础版
                </div>
                <div class="versionTab" :class="version=='2'?'versionTab_active':''" @click="changeVersion">
                  高级版
                </div>
              </div>

            </div>
            <!--基础版-->
            <div class="form_box_1" v-if="version=='1'">
              <!--内容录入、语音上传、选择人物-->
              <div class="form_content_1">
                <div class="form_content_1_1">
                  <p>*内容录入</p>
                  <el-input
                      placeholder="请录入数字人播报的文本，字符长度不超过 2000。"
                      v-model="form.textarea"
                      :rows="6"
                      maxlength="2000"
                      type="textarea"
                      clearable:true
                      resize="none"
                      show-word-limit
                      :disabled="form.audioFile!==''"
                  >
                  </el-input>
                </div>
                <div class="form_content_1_2">
                  <p>语音上传</p>
                  <div>
                    <el-upload
                        class="upload-demo"
                        action="/api/common/upload"
                        :headers="headers"
                        :before-upload="beforeUploadAudio"
                        :on-success="uploadAudioSuccess"
                        :on-error="uploadAudioError"
                        v-if="form.audioFile === ''"
                        style="height: 33px;overflow: hidden"
                    >
                      <el-button size="small" type="primary" plain><i class="el-icon-plus"></i>上传音频</el-button>
                    </el-upload>
                    <div v-else class="alreadyUploadAudio">
                      <div class="fileName">{{ audioFileName }}</div>
                      <i class="el-icon-error" @click="changeAudioFile"></i>
                    </div>
                  </div>

                  <div class="form_content_1_2_1">
                    <p>1.时长不超过 10 分钟</p>
                    <p>2.文件大小不超过120 MB</p>
                    <p>3.支持音频格式为wav、mp3、wma、m4a、aac</p>
                  </div>
                </div>
                <div class="form_content_1_3">
                  <div class="form_content_1_3_1">
                    <img v-if="form.figureId!==''" :src="currentDigitalHumanCharacter.scale_url"
                         style="width: 100%;height: 100%"/>
                  </div>
                  <el-button type="primary" @click="selectPerson = true">选择人物</el-button>
                </div>
              </div>
              <!--是否开启字幕、背景是否透明、分辨率-->
              <!--              <div class="form_content_2">-->
              <!--                <div class="form_content_2_1">-->
              <!--                  <span class="form_content_2_title">*是否开启字幕</span>-->
              <!--                  <el-select v-model="form.isOpenSubtitles">-->
              <!--                    <el-option-->
              <!--                        v-for="item in subtitlesOptions"-->
              <!--                        :key="item.value"-->
              <!--                        :label="item.label"-->
              <!--                        :value="item.value">-->
              <!--                    </el-option>-->
              <!--                  </el-select>-->
              <!--                </div>-->
              <!--                <div class="form_content_2_2">-->
              <!--                  <span class="form_content_2_title">背景是否透明</span>-->
              <!--                  <el-select v-model="form.isBgTransparent" @change="getIsBgTransparent">-->
              <!--                    <el-option-->
              <!--                        v-for="item in BgOptions"-->
              <!--                        :key="item.value"-->
              <!--                        :label="item.label"-->
              <!--                        :value="item.value">-->

              <!--                    </el-option>-->
              <!--                  </el-select>-->
              <!--                </div>-->
              <!--                <div class="form_content_2_3">-->
              <!--                  <span class="form_content_2_title">分辨率</span>-->
              <!--                  <el-select v-model="form.resolution" placeholder="请选择分辨率">-->
              <!--                    <el-option-->
              <!--                        v-for="item in resolutionOptions"-->
              <!--                        :key="item.value"-->
              <!--                        :label="item.label"-->
              <!--                        :value="item.value">-->
              <!--                    </el-option>-->
              <!--                  </el-select>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--分辨率、背景图片-->
              <div class="form_content_3">
                <div class="form_content_3_1">
                  <div class="form_content_2_3">
                    <span style="margin-right: 10px">分辨率</span>
                    <el-select v-model="form.resolution" placeholder="请选择分辨率">
                      <el-option
                          v-for="item in resolutionOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </div>

                  <div style="display: flex;align-items: center">
                    <span style="margin-right: 10px">背景图片</span>
                    <el-upload
                        class="upload-demo"
                        action="/api/common/upload"
                        :headers="headers"
                        :before-upload="beforeUploadBgPic"
                        :on-success="uploadBgPicSuccess"
                        :on-error="uploadBgPicError"
                        v-if="form.bgPicFile === ''"
                        style="height: 33px;width:200px;overflow: hidden;"
                    >
                      <el-button size="small" type="primary" style="width: 200px"
                                 :disabled="form.isBgTransparent === '1'" plain>
                        <i class="el-icon-plus"></i>上传图片
                      </el-button>
                    </el-upload>
                    <div v-else class="alreadyUploadAudio">
                      <div class="fileName">{{ bgPicFileName }}</div>
                      <i class="el-icon-error" @click="changeBgPicFile"></i>
                    </div>
                  </div>
                </div>
                <div class="form_content_3_2" style="padding-left: 305px">
                  （注：当无背景图片，背景为绿色幕布）
                </div>
              </div>
              <!--音色选择-->
              <div class="form_content_4">
                <div class="form_content_4_1">
                  <div class="form_content_4_1_1">

                    <span class="form_content_4_title">*音色选择：</span>

                    <div v-if="form.timbre!==''" class="selectedTimbre">
                      <div style="margin-right: 10px">{{ currentTimbre.nickname }}
                      </div>
                      <el-button type="primary" @click="selectTimbre = true">重新选择</el-button>
                    </div>

                    <el-button style="width: 100px;margin-right: 225px" v-else type="primary"
                               :disabled="form.audioFile!==''" @click="selectTimbre = true">
                      选择音色
                    </el-button>

                    <el-button type="primary" @click="customizedTimbre = true">定制音色</el-button>
                    <el-button type="primary" @click="customizedImage_1 = true">定制形象</el-button>
                  </div>
                </div>
                <div class="form_content_4_2">
                  <div class="form_content_4_2_1">
                    <span class="form_content_4_title">*语速</span>
                    <el-slider
                        :min="0"
                        :max="15"
                        v-model="form.speed"
                        :step="1"
                        :disabled="form.audioFile!==''"
                    >
                    </el-slider>
                    <span style="margin-left: 10px">{{ form.speed }}</span>
                  </div>
                  <div class="form_content_4_2_2">
                    5是正常值，0-15的取值范围。越大语速越快，默认值5 （字面量需要是整数）
                  </div>
                </div>
                <div class="form_content_4_2">
                  <div class="form_content_4_2_1">
                    <span class="form_content_4_title">*音量</span>
                    <el-slider
                        :min="0"
                        :max="15"
                        v-model="form.volume"
                        :step="1"
                        :disabled="form.audioFile!==''"
                    >
                    </el-slider>
                    <span style="margin-left: 10px">{{ form.volume }}</span>
                  </div>

                  <div class="form_content_4_2_2">
                    5是正常值，0-15的取值范围，越大音量越大，默认值5 （字面量需要是整数）
                  </div>
                </div>
                <div class="form_content_4_2">
                  <div class="form_content_4_2_1">
                    <span class="form_content_4_title">*语调</span>
                    <el-slider
                        :min="0"
                        :max="15"
                        v-model="form.intonation"
                        :step="1"
                        :disabled="form.audioFile!==''"
                    >
                    </el-slider>
                    <span style="margin-left: 10px">{{ form.intonation }}</span>
                  </div>
                  <div class="form_content_4_2_2">
                    5是正常值，0-15的取值范围，越大声音越尖，默认值5 （字面量需要是整数）
                  </div>
                </div>
              </div>
              <!--数字人视频所在位置与大小-->
              <div class="form_content_5">
                <div class="form_content_5_1">
                  数字人视频所在位置与大小
                </div>
                <div class="form_content_5_2">
                  <span class="form_content_5_title">水平位置（X）</span>
                  <el-input style="width: 90px;" v-model="form.XPosition"></el-input>
                  <span style="margin-left: 10px">（范围 -0.5 到 0.5 ，取 0 居中，越大数字人越靠右）</span>
                </div>
                <div class="form_content_5_2">
                  <span class="form_content_5_title">垂直位置（Y）</span>
                  <el-input style="width: 90px;" v-model="form.YPosition"></el-input>
                  <span style="margin-left: 10px">（范围 -1 到 1 ，取 0 数字人底部对齐视频底部，越大数字人越靠上）</span>
                </div>
                <div class="form_content_5_2">
                  <span class="form_content_5_title">缩放比例（Z）</span>
                  <el-input style="width: 90px;" v-model="form.scaling"></el-input>
                  <span style="margin-left: 10px">（范围 0.5 到 1.5 ，取1原始比例，越小数字人越小）</span>
                </div>
              </div>
              <!--注意-->
              <div class="form_content_6">
                （注意：y 的取值是按数字人原始大小上下移动。
                当 z 取值大于 1，即放大数字人时，y 的值可以小于 -1 以实现更大范围下移。）
              </div>
              <!--生成-->
              <div class="result_box">
                <el-button type="primary" style="width: 500px;margin-bottom: 10px" @click="submitForm"
                           :disabled="isGenerating">
                  {{ isGenerating ? '正在生成' : '生成视频' }}
                </el-button>
                <div class="result_content" v-if="result_content!==''">
                  <video controls :src="result_content"></video>
                </div>
              </div>


            </div>

            <!--高级版-->
            <div class="form_box_1" v-if="version=='2'">
              <!--内容标题录入、语音上传、选择人物-->
              <div class="form_content_7">
                <div class="form_content_7_1">
                  <p>*内容与标题录入</p>
                  <el-input
                      placeholder="如果缺少则视频中没有标题，长度限制30"
                      v-model="form.title"
                      maxlength="30"
                      type="text"
                      clearable:true
                      resize="none"
                      show-word-limit
                      style="margin-bottom: 10px"
                      :disabled="form.audioFile!==''"
                  >
                  </el-input>
                  <el-input
                      placeholder="请录入数字人播报的文本，字符长度不超过 2000。"
                      v-model="form.textarea"
                      :rows="6"
                      maxlength="2000"
                      type="textarea"
                      clearable:true
                      resize="none"
                      show-word-limit
                      :disabled="form.audioFile!==''"
                  >
                  </el-input>
                </div>
                <div class="form_content_7_2">
                  <p>语音上传</p>
                  <div>
                    <el-upload
                        class="upload-demo"
                        action="/api/common/upload"
                        :headers="headers"
                        :before-upload="beforeUploadAudio"
                        :on-success="uploadAudioSuccess"
                        :on-error="uploadAudioError"
                        v-if="form.audioFile === ''"
                        style="height: 33px;overflow: hidden"
                    >
                      <el-button size="small" type="primary" plain><i class="el-icon-plus"></i>上传音频</el-button>
                    </el-upload>
                    <div v-else class="alreadyUploadAudio">
                      <div class="fileName">{{ audioFileName }}</div>
                      <i class="el-icon-error" @click="changeAudioFile"></i>
                    </div>
                  </div>

                  <div class="form_content_7_2_1">
                    <p>1.时长不超过 10 分钟</p>
                    <p>2.文件大小不超过120 MB</p>
                    <p>3.支持音频格式为wav、mp3、wma、m4a、aac</p>
                  </div>
                </div>
                <div class="form_content_7_3">
                  <div class="form_content_7_3_1">
                    <img v-if="form.figureId!==''" :src="currentDigitalHumanCharacter.scale_url"
                         style="width: 100%;height: 100%"/>
                  </div>
                  <el-button type="primary" @click="selectPerson_2 = true">选择人物</el-button>
                </div>
              </div>
              <!--视频LOGO、分辨率-->
              <div class="form_content_8">
                <div class="form_content_8_1">
                  <div class="form_content_8_1_1">
                    <span style="margin-right: 10px">视频LOGO</span>
                    <el-upload
                        class="upload-demo"
                        action="/api/common/upload"
                        :headers="headers"
                        :before-upload="beforeUploadVideoLogo"
                        :on-success="uploadVideoLogoSuccess"
                        :on-error="uploadVideoLogoError"
                        v-if="form.videoLogo === ''"
                        style="height: 33px;width:150px;overflow: hidden;"
                    >
                      <el-button size="small" type="primary" style="width: 150px" plain>
                        <i class="el-icon-plus"></i>上传图片
                      </el-button>
                    </el-upload>
                    <div v-else class="alreadyUploadAudio">
                      <div class="fileName">{{ videoLogoFileName }}</div>
                      <i class="el-icon-error" @click="changeVideoLogoFile"></i>
                    </div>
                  </div>
                  <div class="form_content_8_1_2">
                    <span style="margin-right: 10px">分辨率</span>
                    <el-select v-model="form.resolution" placeholder="请选择分辨率">
                      <el-option
                          v-for="item in resolutionOptions_2"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </div>

                </div>
                <div class="form_content_8_2">
                  （标准：文件大小不超过 3 MB；支持的图片格式：png、jpg、jpeg、bmp）
                </div>
              </div>
              <!--背景设置-->
              <div class="form_content_9">
                <!--                <div class="form_content_9_1">-->
                <!--                  <span style="margin-bottom: 20px">背景是否透明</span>-->
                <!--                  <el-select v-model="form.isBgTransparent" @change="getIsBgTransparent">-->
                <!--                    <el-option-->
                <!--                        v-for="item in BgOptions"-->
                <!--                        :key="item.value"-->
                <!--                        :label="item.label"-->
                <!--                        :value="item.value">-->
                <!--                    </el-option>-->
                <!--                  </el-select>-->
                <!--                </div>-->
                <div class="form_content_9_2">
                  <!--背景图片-->
                  <div class="form_content_9_2_1">
                    <span>背景图片</span>
                    <el-upload
                        class="upload-demo"
                        action="/api/common/upload"
                        :headers="headers"
                        :before-upload="beforeUploadBgPic"
                        :on-success="uploadBgPicSuccess"
                        :on-error="uploadBgPicError"
                        v-if="form.bgPicFile === ''"
                        :disabled="form.bgVideoFile !==''"
                        style="height: 33px;width:130px;overflow: hidden;"
                    >
                      <el-button size="small" type="primary" style="width: 130px" :disabled="form.bgVideoFile !==''"
                                 plain>
                        <i class="el-icon-plus"></i>上传图片
                      </el-button>
                    </el-upload>
                    <div v-else class="alreadyUploadAudio">
                      <div class="fileName">{{ bgPicFileName }}</div>
                      <i class="el-icon-error" @click="changeBgPicFile"></i>
                    </div>
                    <span>注：当无背景时，背景为绿色幕布</span>
                  </div>
                  <div class="form_content_9_2_2">
                    （标准：文件大小不超过 3 MB；支持的图片格式：png、jpg、jpeg、bmp）
                  </div>
                  <!--背景图片-->
                  <div class="form_content_9_2_1">
                    <span>背景视频</span>
                    <el-upload
                        class="upload-demo"
                        action="/api/common/upload"
                        :headers="headers"
                        :before-upload="beforeUploadBgVideo"
                        :on-success="uploadBgVideoSuccess"
                        :on-error="uploadBgVideoError"
                        v-if="form.bgVideoFile === ''"


                        style="height: 33px;width:130px;overflow: hidden;"
                    >
                      <el-button size="small" type="primary" style="width: 130px" plain :disabled="bgPicFileName !==''">
                        <i class="el-icon-plus"></i>上传视频
                      </el-button>
                    </el-upload>
                    <div v-else class="alreadyUploadAudio">
                      <div class="fileName">{{ bgVideoFileName }}</div>
                      <i class="el-icon-error" @click="changeBgVideoFile"></i>
                    </div>
                    <span>注：当无背景时，背景为绿色幕布</span>
                  </div>
                  <div class="form_content_9_2_2">
                    （标准：文件大小不超过 500 MB；支持的视频格式：mp4）
                  </div>
                </div>
              </div>
              <!--音色选择-->
              <div class="form_content_4">
                <div class="form_content_4_1">
                  <div class="form_content_4_1_1">

                    <span class="form_content_4_title">*音色选择：</span>

                    <div v-if="form.timbre!==''" class="selectedTimbre">
                      <div style="margin-right: 10px">{{ currentTimbre.nickname }}
                      </div>
                      <el-button type="primary" @click="selectTimbre = true">重新选择</el-button>
                    </div>

                    <el-button style="width: 100px;margin-right: 225px" v-else type="primary"
                               :disabled="form.audioFile!==''" @click="selectTimbre = true">
                      选择音色
                    </el-button>

                    <el-button type="primary" @click="customizedTimbre = true">定制音色</el-button>
                    <el-button type="primary" @click="customizedImage_2 = true">定制形象</el-button>
                  </div>
                </div>
                <div class="form_content_4_2">
                  <div class="form_content_4_2_1">
                    <span class="form_content_4_title">*语速</span>
                    <el-slider
                        :min="0"
                        :max="15"
                        v-model="form.speed"
                        :step="1"
                    >
                    </el-slider>
                    <span style="margin-left: 10px">{{ form.speed }}</span>
                  </div>
                  <div class="form_content_4_2_2">
                    5是正常值，0-15的取值范围。越大语速越快，默认值5 （字面量需要是整数）
                  </div>
                </div>
                <div class="form_content_4_2">
                  <div class="form_content_4_2_1">
                    <span class="form_content_4_title">*音量</span>
                    <el-slider
                        :min="0"
                        :max="15"
                        v-model="form.volume"
                        :step="1"
                    >
                    </el-slider>
                    <span style="margin-left: 10px">{{ form.volume }}</span>
                  </div>

                  <div class="form_content_4_2_2">
                    5是正常值，0-15的取值范围，越大音量越大，默认值5 （字面量需要是整数）
                  </div>
                </div>
                <div class="form_content_4_2">
                  <div class="form_content_4_2_1">
                    <span class="form_content_4_title">*语调</span>
                    <el-slider
                        :min="0"
                        :max="15"
                        v-model="form.intonation"
                        :step="1"
                    >
                    </el-slider>
                    <span style="margin-left: 10px">{{ form.intonation }}</span>
                  </div>
                  <div class="form_content_4_2_2">
                    5是正常值，0-15的取值范围，越大声音越尖，默认值5 （字面量需要是整数）
                  </div>
                </div>
              </div>
              <!--              &lt;!&ndash;数字人视频所在位置与大小&ndash;&gt;-->
              <!--              <div class="form_content_5">-->
              <!--                <div class="form_content_5_1">-->
              <!--                  数字人视频所在位置与大小-->
              <!--                </div>-->
              <!--                <div class="form_content_5_2">-->
              <!--                  <span class="form_content_5_title">水平位置（X）</span>-->
              <!--                  <el-input style="width: 90px;" v-model="form.XPosition"></el-input>-->
              <!--                  <span style="margin-left: 10px">（范围 -0.5 到 0.5 ，取 0 居中，越大数字人越靠右）</span>-->
              <!--                </div>-->
              <!--                <div class="form_content_5_2">-->
              <!--                  <span class="form_content_5_title">垂直位置（Y）</span>-->
              <!--                  <el-input style="width: 90px;" v-model="form.YPosition"></el-input>-->
              <!--                  <span style="margin-left: 10px">（范围 -1 到 1 ，取 0 数字人底部对齐视频底部，越大数字人越靠上）</span>-->
              <!--                </div>-->
              <!--                <div class="form_content_5_2">-->
              <!--                  <span class="form_content_5_title">缩放比例（Z）</span>-->
              <!--                  <el-input style="width: 90px;" v-model="form.scaling"></el-input>-->
              <!--                  <span style="margin-left: 10px">（范围 0 到 2 ，取1原始比例，越小数字人越小）</span>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              &lt;!&ndash;注意&ndash;&gt;-->
              <!--              <div class="form_content_6">-->
              <!--                （注意：y 的取值是按数字人原始大小上下移动。-->
              <!--                当 z 取值大于 1，即放大数字人时，y 的值可以小于 -1 以实现更大范围下移。）-->
              <!--              </div>-->

              <!--背景音乐-->
              <div class="form_content_10">
                <div class="form_content_10_1">
                  <span style="margin-right: 10px">视频BGM背景音乐</span>
                  <div style="width: calc(100% - 150px)">
                    <el-upload
                        class="upload-demo"
                        action="/api/common/upload"
                        :headers="headers"
                        :before-upload="beforeUploadAudio"
                        :on-success="uploadBGMSuccess"
                        :on-error="uploadBGMError"
                        v-if="form.bgm === ''"
                        style="height: 33px;overflow: hidden"
                    >
                      <el-button size="small" type="primary" plain><i class="el-icon-plus"></i>上传音频</el-button>
                    </el-upload>
                    <div v-else class="alreadyUploadAudio">
                      <div class="fileName">{{ bgmFileName }}</div>
                      <i class="el-icon-error" @click="changeBGMFile"></i>
                    </div>
                  </div>
                </div>


                <div class="form_content_10_2">
                  <p>（标准：文件大小不超过 120 MB；支持的音频格式：wav、mp3、wma、m4a）</p>
                </div>
              </div>

              <!--生成-->
              <div class="result_box">
                <el-button type="primary" style="width: 500px;margin-bottom: 10px" @click="submitForm"
                           :disabled="isGenerating">
                  {{ isGenerating ? '正在生成……请耐心等待' : '生成视频' }}
                </el-button>
                <div class="result_content" v-if="result_content!==''">
                  <video controls :src="result_content"></video>
                </div>
              </div>


            </div>

          </div>
          <aiNav/>
        </div>
      </div>
    </div>
    <!--选择人物弹框-基础版-->
    <el-dialog
        title="选择人物-基础版"
        :visible.sync="selectPerson"
        width="555px"
        class="selectDialog"
    >
      <div class="selectDialog_content">
        <el-table
            :data="digitalHumanCharacterList"
            height="540"
            stripe
            style="width: 100%;">
          <el-table-column label="默认人物" header-align="center" width="100" align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.checked" :data-a="checkeds"
                           @change="chooseDigitalHuman(scope.row)"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="nickname" label="昵称" width="100" header-align="center"
                           align="center"></el-table-column>
          <el-table-column prop="figureid" label="ID" width="200" header-align="center"
                           align="center"></el-table-column>
          <el-table-column prop="scene" label="形象" width="100" header-align="center" align="center">
            <template slot-scope="scope">
              <!--              <img :src="scope.row.pic_url" style="width: 70px;height: 120px"/>-->
              <el-tooltip class="item" effect="dark" content="点击预览大图" placement="right">
                <el-image
                    style="width: 70px; height: 120px"
                    :src="scope.row.scale_url"
                    :preview-src-list="[scope.row.pic_url]">
                </el-image>
              </el-tooltip>

            </template>
          </el-table-column>

        </el-table>
        <div class="selectDialog_content_bottom">
          <div class="selectedTimbre">
            <span>已选择：</span>
            <span v-if="currentDigitalHumanCharacter.figureid">{{
                currentDigitalHumanCharacter.nickname
              }}(ID:{{ currentDigitalHumanCharacter.figureid }})</span>
          </div>
          <el-button type="primary" @click="comfirmDigitalHuman">确认选择</el-button>
        </div>
      </div>

    </el-dialog>

    <!--选择人物弹框-高级版-->
    <el-dialog
        title="选择人物-高级版"
        :visible.sync="selectPerson_2"
        width="555px"
        class="selectDialog"
    >
      <el-tabs v-model="selectPerson_2_tab">
        <el-tab-pane label="选择人像" name="first">
          <div class="selectDialog_content" style="height: 530px">
            <el-table
                :data="digitalHumanCharacterList_2"
                height="530"
                stripe
                style="width: 100%;">
              <el-table-column label="默认人物" header-align="center" width="100" align="center">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.checked" :data-a="checkeds"
                               @change="chooseDigitalHuman(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="nickname" label="昵称" width="100" header-align="center"
                               align="center"></el-table-column>
              <el-table-column prop="figureid" label="ID" width="200" header-align="center"
                               align="center"></el-table-column>
              <el-table-column prop="scene" label="形象" width="100" header-align="center" align="center">
                <template slot-scope="scope">
                  <!--              <img :src="scope.row.pic_url" style="width: 70px;height: 120px"/>-->
                  <el-tooltip class="item" effect="dark" content="点击预览大图" placement="right">
                    <el-image
                        style="width: 70px; height: 120px"
                        :src="scope.row.scale_url"
                        :preview-src-list="[scope.row.pic_url]">
                    </el-image>
                  </el-tooltip>

                </template>
              </el-table-column>

            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="选择模版" name="second">
          <div class="selectDialog_content" style="height: 530px">
            <el-table
                :data="templateList"
                height="530"
                stripe
                style="width: 100%;">
              <el-table-column label="状态" header-align="center" width="100" align="center">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.checked" :data-a="checkeds"
                               @change="chooseTemplate(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="templateid" label="templateID" width="100" header-align="center"
                               align="center"></el-table-column>
              <el-table-column prop="proportion" label="比例" width="200" header-align="center"
                               align="center"></el-table-column>
              <el-table-column prop="scene" label="模版预览" width="100" header-align="center" align="center">
                <template slot-scope="scope">
                  <!--              <img :src="scope.row.pic_url" style="width: 70px;height: 120px"/>-->
                  <el-tooltip class="item" effect="dark" content="点击预览大图" placement="right">
                    <el-image
                        style="width: 70px; height: 120px"
                        :src="scope.row.scale_url"
                        :preview-src-list="[scope.row.pic_url]">
                    </el-image>
                  </el-tooltip>
                </template>
              </el-table-column>

            </el-table>

          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="selectDialog_content_bottom_2">
        <div class="selectedTimbre_2">
          <div>
            <span>已选择人像：</span>
            <span v-if="currentDigitalHumanCharacter.figureid">{{
                currentDigitalHumanCharacter.nickname
              }}(ID:{{ currentDigitalHumanCharacter.figureid }})</span>
          </div>
          <div>
            <span>已选择模版：</span>
            <span v-if="currentTemplate.templateid">{{ currentTemplate.templateid }}</span>
          </div>
        </div>
        <el-button type="primary" @click="comfirmDigitalHuman_2">确认选择</el-button>
      </div>


    </el-dialog>
    <!--选择音色弹框-->
    <el-dialog
        title="选择音色"
        :visible.sync="selectTimbre"
        width="1200px"
        stripe
        class="selectDialog"
    >
      <div class="selectDialog_content">
        <el-table
            :data="timbreList"
            height="540"
            stripe
            style="width: 100%;">
          <el-table-column label="状态" width="50" header-align="center" align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.checked" :data-a="checkeds"
                           @change="chooseTimbre(scope.row)"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="nickname" label="音色名称" width="150" header-align="center"
                           align="center"></el-table-column>
          <el-table-column prop="figureid" label="音色ID" width="150" header-align="center"
                           align="center"></el-table-column>
          <el-table-column prop="sex" label="性别" width="50" header-align="center" align="center"></el-table-column>
          <el-table-column prop="style" label="风格" width="200" header-align="center" align="center"></el-table-column>
          <el-table-column prop="scene" label="适用场景" width="200" header-align="center"
                           align="center"></el-table-column>
          <el-table-column label="试听音频文件">
            <template slot-scope="scope">
              <audio controls :src="scope.row.audio_url"></audio>
            </template>
          </el-table-column>
        </el-table>
        <div class="selectDialog_content_bottom">
          <div class="selectedTimbre">
            <span>已选择：</span>
            <span v-if="currentTimbre.figureid">{{ currentTimbre.nickname }}(ID:{{ currentTimbre.figureid }})</span>
          </div>
          <el-button type="primary" @click="comfirmTimbre">确认选择</el-button>
        </div>
      </div>

    </el-dialog>

    <!--定制声音弹框-->
    <el-dialog
        title="定制音色"
        :visible.sync="customizedTimbre"
        width="900px"
        stripe
        class="selectDialog"
    >
      <div class="selectDialog_content" style="height: 510px">
        <h3>音频要求</h3>
        <p>1.上传的音频文件格式需为：mp3、m4a、wav格式；</p>
        <p>2.上传的音频文件的时长最少应不低于30秒，最长应不超过5分钟；</p>
        <p>3.上传的音频文件大小需不超过20MB。</p>
        <p> 录制环境
          录音环境的选择主要考虑降低噪音和混响，建议使用10平方米以内的小房间进行录音，有吸音装置的房间是理想环境。可使用价格低廉的吸音棉对房间进行改造，目的是为了改变声波的平面反射为漫反射，降低混响。</p>
        <p>1. 尽量在同一声学环境下录制，避免过于喧哗的背景音和噪音，音频非拼接而成。</p>
        <p> 2.
          避免远场环境下录制，建议离麦克风50cm之内,并需要保持位置、距离稳定，防止音量大小不一致。不要让讲话的气流正对麦克风，防止喷麦造成的噪音。</p>
        <p> 3. 尽量在自然对话状态下录制,对比刻意压低声线/呢喃获得的音色会更贴合本人。</p>
        <h3>音频质量</h3>
        <p> 1. 必须仅含有一个人在说话，避免多个人同时说话。</p>
        <p>2. 说话人发音及音质越清晰、复刻质量越高。如说话人喉音/声音底噪严重，则复刻音频喉音和底噪将会较为严重。</p>
        <p> 3.
          录音时需要保持音量大小、语速稳定、注意断句、避免口腔噪音(如口水声)、杂音、混响等情况。结合最终使用场景与人设加入适当演绎，避免朗读风格过于严重，并保持整体风格一致。</p>
        <p> 4.
          录音时尽量保持姿态稳定，减少不必要的肢体动作，椅子的响声、衣物的摩擦音、鼠标键盘的敲击声都是常见的人为噪声，需要格外注意细节。</p>
        <p>5. 如口误无需终止录音，可停顿1~2秒后，继续录制即可。</p>
        <p>录制内容
          建议在录音前熟悉文案，并确定好人设及演绎风格。尽量贴近复刻内容和主题，避免说话人韵律过于平淡，否则将得到较平的复刻效果。如需要复刻情绪韵律起伏较强的音频，请尽量输入表现力强的音频。尽量不要尝试复刻小孩或者老人的音频。</p>
        <el-button type="primary" style="width: 500px;margin-top: 30px;margin-left: 172px"
                   @click="addCustomer = true">申请定制
        </el-button>
        <el-dialog
            width="30%"
            title="添加客服微信"
            :visible.sync="addCustomer"
            class="selectDialog"
            append-to-body>
          <img src="@/assets/ai/example/add.jpg" style="width: 100%">
        </el-dialog>
      </div>
    </el-dialog>
    <!--定制形象弹框-基础版-->
    <el-dialog
        title="定制形象-基础版"
        :visible.sync="customizedImage_1"
        width="900px"
        stripe
        class="selectDialog"
    >
      <div class="selectDialog_content" style="overflow-y: scroll">
        <div class="shootingGuide">
          <p>2D数字人极速版形象定制时，我们需要您提供一段1 -
            4分钟自然闭嘴的真人视频。为了提升最终生成的数字人效果，我们特别准备了以下拍摄指南，供您参考：</p>

          <h2>示例视频部分片段</h2>
          <ul>
            <li>实景拍摄：训练固定背景数字人（保留原背景）</li>
            <img src="@/assets/ai/example/6.png">
            <li>绿幕拍摄：训练透明背景数字人（可替换任意背景）</li>
            <img src="@/assets/ai/example/7.png">
          </ul>

          <div class="section">
            <h2>1 拍摄准备</h2>
            <h3>1.1模特服饰</h3>
            <ul>
              <li>可按照模特喜好选择，与背景颜色风格协调、适合实际应用场景即可</li>
              <li>合身，不能过大或过紧</li>
              <li>整洁大方，无明显褶皱</li>
              <li>穿低领衣服，露出脖子</li>
              <li>避免碎花、格纹、条纹等大面积花纹的衣服，避免摩尔纹产生</li>
              <li>建议着深色服装，或者选择其他与背景颜色反差大的服饰颜色</li>
              <li>不建议选择白色、反光材质、过于光滑面料，会反绿（绿幕拍摄需注意）</li>
              <li>服饰中不存在绿色系服饰，不存在纱质材料，会漏绿（绿幕拍摄需注意）</li>
            </ul>
            <h3>1.2模特妆造</h3>
            <ul>
              <li>妆容自然大方，有亲和力，无明显突兀感</li>
              <li>面部白皙且有立体感，但不宜有过重阴影</li>
              <li>如果存在瑕疵，例如皮肤不平整，长痘，手部存在血丝，需要遮瑕</li>
              <li>嘴唇过薄可通过口红补救</li>
              <li>露出双耳，可佩戴固定耳饰，不建议佩戴易晃动耳饰</li>
              <li>注意手部、脖子、腿等部位，需要保持与面部肤色一致</li>
              <li>头发正面看无碎发、无毛刺、无镂空，饱满有弧度（绿幕拍摄需注意）</li>
            </ul>
            <h3>1.3布景</h3>
            <ul>
              <li>实景拍摄：背景整洁、无密集纹理，适合实际应用场景</li>
              <li>绿幕拍摄：绿幕颜色统一，且平整无褶皱；建议人物和绿幕距离大于2米，避免人物反绿</li>
            </ul>
            <h3>1.4灯光</h3>
            <ul>
              <li>用灯光从斜前侧方照亮模特面部，使主播在画面中更突出</li>
              <li>在灯光下，模特皮肤白皙透亮，避免脸部反光、肤色暗沉或曝光</li>
              <li>模特光线均匀，尤其注意脸部，颈部，手部，腿部的光照一致性</li>
              <li>背景光照均匀，并与人像轮廓形成清晰边界</li>
              <li>灯光一旦确定，切勿再次调整，否则全部重拍</li>
            </ul>
            <h3>1.5设备</h3>
            <ul>
              <li>建议拍摄的分辨率不低于1080P，关闭HDR模式，关闭log模式</li>
              <li>比例：横屏为16:9，竖屏为9:16（若最终的场景用于竖屏，建议使用竖屏拍摄；横屏同理）</li>
              <li>
                角度：镜头高度与主播眼睛保持同一水平线，模特在头部保持自然的情况下平视镜头，建议正对镜头，侧身不超过10度，不要仰头、低头、仰视、俯视
              </li>
              <li>构图：将主播放置于画面中间，确保五官清晰、面部无遮挡</li>
              <li>可使用三脚架或稳定器固定拍摄设备，以减少画面抖动，确保视频流畅稳定</li>
            </ul>
          </div>

          <div class="section">
            <h2>2形象拍摄</h2>
            <h3>2.1面部神情</h3>
            <p>
              头部保持相对稳定，模仿说话状态轻微晃动，面带微笑，面部肌肉放松，注意眼睛一直看镜头，避免张口露齿、抿嘴、眼睛四处看（无需说话）</p>
            <h3>2.2模特动作</h3>
            <p>越自然越好，以下建议仅供参考：</p>
            <ul>
              <li>
                尽量选择通用的小幅动作，比如摊手、合手、上下叠手等（细微的手部动作会更自然），注意避免摆手、摇头、比数字等不通用动作，动作的含义需适配数字人播报的所有文本及场景，生成的数字人姿态动作将与视频完全一致，请确保拍摄时的姿态可用于后续内容创作
              </li>
              <li>手势动作避免遮挡嘴巴、面部、脖子周围，也避免出画面</li>
              <li>可根据应用场景决定动作
                <ul>
                  <li>
                    视频场景：更适合把动作幅度控制在身前，增加一些自然的手部小动作、偶尔有摊手等较大幅度动作会更有聊天感
                  </li>
                  <li>
                    交互场景：头部微微自然晃动、身体稳定、手部自然放在身前即可，不需要做过多动作，因为交互场景某些时候数字人处于聆听静默状态，这时数字人不需要做过多动作
                  </li>
                  <li>直播场景：可自然闭嘴模拟一场真实的直播，可让主播假装在介绍产品、与用户互动等</li>
                </ul>
              </li>
            </ul>
          </div>

          <div class="tip">
            <p>tips：</p>
            <ul>
              <li>可以连续拍摄4分钟左右，为剪辑提供更多的素材</li>
              <li>可以播放一段文本内容，主播想象自己在播报并自然配合神情和手势</li>
              <img src="@/assets/ai/example/8.png">
            </ul>
          </div>

          <div class="section">
            <h2>3后期处理</h2>
            <h3>3.1 剪辑 - 调色美颜</h3>
            <ul>
              <li>需掐头去尾剪辑，最终提供的底板视频需全程无拼接，跳帧对视频效果影响较大</li>
              <li>开头保留2 - 3秒没有手势的静默状态，可以使动作自然开始</li>
              <li>片段删减说明
                <ul>
                  <li>不建议保留：不通用动作、指向性动作、否定动作、眼神乱瞟等不合时宜的动作</li>
                  <li>可以保留：偶尔几秒钟的露齿对训练效果基本没影响</li>
                </ul>
              </li>
              <li>主播动作过快或过慢时，可以适当变速，目前来看0.8 - 1.3倍速基本不会影响效果</li>
              <li>如果主播有美颜需求，也可以在剪辑时用美颜软件还原美貌，数字人训练效果会与视频素材保持一致</li>
              <li>最终底板视频时长要求1 - 4分钟，2 - 3分钟为宜</li>
              <li>若无需抠绿，可直接导出视频，实景拍摄的底板视频规格要求如本文【4.2 视频规格要求】</li>
            </ul>
            <h3>3.2 抠绿（绿幕拍摄）</h3>
            <p>以下为AE（Adobe After Effects）抠绿方式及导出视频规格，也可选择其他软件进行抠绿处理。</p>
            <h4>3.2.1对视频添加超级键效果</h4>
            <img src="@/assets/ai/example/9.png">
            <h4>3.2.2调整各种参数</h4>
            <p>抠除绿色背景，去除模特脸部以及身上绿色反光</p>
            <img src="@/assets/ai/example/10.png">
            <h4>3.2.3检查抠绿效果</h4>
            <p>抠绿后可换浅灰色背景，检查抠绿是否干净以及是否有该保留的部分被抠除</p>
            <img src="@/assets/ai/example/11.png">
            <h4>3.2.4导出视频</h4>
            <p>方案一（推荐）：</p>
            <ol>
              <li>导出透明背景的带alpha通道（预乘模式）的视频
                <ul>
                  <li>格式：mov（Apple Prores 4444或Apple Prores 4444 XQ编码）</li>
                  <li>帧率：25fps</li>
                  <li>
                    分辨率：1080P、2K、4K（2K、4K人像训练限时免费，只支持用于视频合成场景，不支持交互、直播等实时渲染推流场景）
                  </li>
                </ul>
              </li>
              <img src="@/assets/ai/example/12.png">
              <li>使用ffmpeg将mov转换成带alpha通道的webm视频，webm视频规格要求如本文【4.2 视频规格要求】</li>
              <pre>ffmpeg -i input.mov -c:v libvpx-vp9 -pix_fmt yuva420p -lossless 1 -b:v 0 output.webm</pre>
            </ol>
            <p>方案二：导出浅灰色背景的底板视频和mask视频，选择【mask模式】方式如下</p>
            <img src="@/assets/ai/example/13.png">
          </div>

          <div class="section">
            <h2>4 视频自检</h2>
            <p>在提交视频前，请务必逐条确认视频素材是否满足以下要求。</p>
            <h3>4.1 内容要求</h3>
            <ul>
              <li>自然闭嘴，不说话、不张嘴、不露牙齿</li>
              <li>动作自然，无指向性动作</li>
              <li>头部无大角度转动，脸部全程无遮挡</li>
              <li>提供的视频全程无拼接、无跳帧</li>
              <li>人像边缘平滑自然，没有绿色残留或锯齿状边缘（若抠绿需注意）</li>
              <li>穿低领衣服，露出脖子</li>
              <li>光线充足，脸部整体光线均匀</li>
              <li>保持妆造、光线、景别不变</li>
            </ul>
          </div>
          <el-button type="primary" style="width: 500px;margin-top: 30px;margin-left: 172px"
                     @click="addCustomer = true">申请定制
          </el-button>
        </div>
        <el-dialog
            width="30%"
            title="添加客服微信"
            :visible.sync="addCustomer"
            class="selectDialog"
            append-to-body>
          <img src="@/assets/ai/example/add.jpg" style="width: 100%">
        </el-dialog>

      </div>
    </el-dialog>
    <!--定制形象弹框-高级版-->
    <el-dialog
        title="定制形象-高级版"
        :visible.sync="customizedImage_2"
        width="900px"
        stripe
        class="selectDialog"
    >
      <div class="selectDialog_content">

      </div>
    </el-dialog>
    <footerBot/>
  </div>
</template>

<script>
import aiNav from '@/components/aiNav.vue'
import ai_select from '@/components/ai_select.vue'
import ai_title from '@/components/ai_title.vue'
import ai_img from '@/components/ai_img'
import ai_switch from '@/components/ai_switch'
import ai_button_file_data from '@/components/ai_button_file_data'
import router from "@/router";
import {goAi} from "@/util/goAi";

export default {
  components: {
    aiNav,
    ai_select,
    ai_title,
    ai_img,
    ai_switch,
    ai_button_file_data
  },
  mounted() {
    this.getUserInfo()
    this.get3DdigitalHumans()
    this.get3DdigitalHumans_2()
    this.getPublicSoundLibrary()
    this.getTemplate()
  },
  watch: {
    'form.XPosition': {
      handler(newVal) {
        let num = Number(newVal)
        console.log(num)
        if (isNaN(num)) {
          this.form.XPosition = '0'
        }
      }
    },
    'form.YPosition': {
      handler(newVal) {
        let num = Number(newVal)
        console.log(num)
        if (isNaN(num)) {
          this.form.YPosition = '0'
        }
      }
    },
    'form.scaling': {
      handler(newVal) {
        let num = Number(newVal)
        console.log(num)
        if (isNaN(num)) {
          this.form.scaling = '1'
        }
      }
    }

  },
  data() {
    return {
      // 添加客服
      addCustomer: false,

      // 定制音色弹框状态变量
      customizedTimbre: false,
      // 定制形象-基础版 弹框状态变量
      customizedImage_1: false,
      // 定制形象-高级版 弹框状态变量
      customizedImage_2: false,

      // 选择人物弹框状态变量
      selectPerson: false,
      // 选择人物-高级版弹框状态变量
      selectPerson_2: false,
      // 数字人人物选择列表
      digitalHumanCharacterList: [],
      // 数字人人物选择列表-高级版
      digitalHumanCharacterList_2: [],
      // 模版库列表
      templateList: [],
      // 当前选择的数字人
      currentDigitalHumanCharacter: {},
      // 当前选择模版
      currentTemplate: {},

      // 选择人物-高级版弹框-tab切换变量
      selectPerson_2_tab: 'first',

      // 选择音色弹框状态变量
      selectTimbre: false,
      // 音色选项列表
      timbreList: [],
      // 已选音色
      currentTimbre: {},

      // 更新视图
      checkeds: false,

      // 版本选择
      version: '1',
      // 请求头
      headers: {
        token: '',
      },

      // 音频文件名
      audioFileName: '',
      // 背景图片文件名
      bgPicFileName: '',
      // 背景视频文件名
      bgVideoFileName: '',
      // 视频LOGO图片名
      videoLogoFileName: '',
      // 背景音乐文件名
      bgmFileName: '',
      // 分辨率选项列表
      resolutionOptions: [
        {
          label: '720x1280px(竖屏)',
          value: '720x1280'
        },
        {
          label: '1080x1440px(竖屏)',
          value: '1080x1440'
        },
        {
          label: '1080x1920px(竖屏)',
          value: '1080x1920'
        },
        {
          label: '1280x720px(横屏)',
          value: '1280x720'
        },
        {
          label: '1920x1080px(横屏)',
          value: '1920x1080'
        },
        {
          label: '2560x1440px(横屏)',
          value: '2560x1440'
        },
        {
          label: '3840x2160px(横屏)',
          value: '3840x2160'
        },
      ],
      // 分辨率选项列表-高级版
      resolutionOptions_2: [
        {
          label: '1080x1920px(竖屏)',
          value: '1080x1920'
        },
        {
          label: '1920x1080px(横屏)',
          value: '1920x1080'
        },
      ],
      form: {
        // 内容录入
        textarea: '',
        //   音频文件
        audioFile: '',
        //   分辨率
        resolution: '',
        //   背景图片
        bgPicFile: '',
        // 背景视频
        bgVideoFile: '',
        //   音色
        timbre: '',
        //   语速
        speed: 5,
        //   音量
        volume: 5,
        //   语调
        intonation: 5,
        //   水平位置
        XPosition: '0',
        //   垂直位置
        YPosition: '0',
        //   缩放比例
        scaling: '1',
        // 视频LOGO
        videoLogo: '',
        // 背景音乐
        bgm: '',
        // 数字人模板ID
        templateid: '',
        //   数字人人像ID
        figureId: '',
      },
      // 是否正在生成视频
      isGenerating: false,
      //   生成结果
      result_content: '',
    }
  },
  methods: {
    // 确认选择音色
    comfirmTimbre() {
      if (this.currentTimbre.figureid) {
        this.selectTimbre = false
        this.form.timbre = this.currentTimbre.figureid
        this.$message({
          message: '已选择音色',
          type: 'success'
        });
      } else {
        this.$message({
          message: '还未选择音色',
          type: 'warning'
        });
      }
    },

    // 选择音色
    chooseTimbre(item) {
      this.checkeds = !this.checkeds
      // console.log(item)
      this.currentTimbre = item
      this.timbreList.forEach(el => {
        if (el.id !== item.id) {
          el.checked = false
        }
      })
      if (item.checked === false) {
        this.currentTimbre = {}
        this.form.timbre = ''
      }
    },
    // 确认选择数字人
    comfirmDigitalHuman() {
      if (this.currentDigitalHumanCharacter.figureid) {
        this.selectPerson = false
        this.form.figureId = this.currentDigitalHumanCharacter.figureid
        this.$message({
          message: '已选择人物',
          type: 'success'
        });
      } else {
        this.$message({
          message: '还未选择人物',
          type: 'warning'
        });
      }
    },
    // 确认选择数字人、模板
    comfirmDigitalHuman_2() {
      if (this.currentDigitalHumanCharacter.figureid && this.currentTemplate.templateid) {
        this.selectPerson_2 = false
        this.form.figureId = this.currentDigitalHumanCharacter.figureid
        this.form.templateid = this.currentTemplate.templateid
        this.$message({
          message: '选择成功',
          type: 'success'
        });
      } else {
        this.$message({
          message: '未选择数字人人像或模板',
          type: 'warning'
        });
      }
    },
    // 选择数字人
    chooseDigitalHuman(item) {
      this.checkeds = !this.checkeds
      // console.log(item)
      this.currentDigitalHumanCharacter = item
      this.digitalHumanCharacterList.forEach(el => {
        if (el.id !== item.id) {
          el.checked = false
        }
      })
      this.digitalHumanCharacterList_2.forEach(el => {
        if (el.id !== item.id) {
          el.checked = false
        }
      })
      if (item.checked === false) {
        this.currentDigitalHumanCharacter = {}
        this.form.figureId = ''
      }
    },
    // 选择模版
    chooseTemplate(item) {
      this.checkeds = !this.checkeds
      // console.log(item)
      this.currentTemplate = item
      // console.log(this.currentTemplate)
      this.templateList.forEach(el => {
        if (el.id !== item.id) {
          el.checked = false
        }
      })
      if (item.checked === false) {
        this.currentTemplate = {}
      }
    },
    // 获取2d数字人展示-基础版
    get3DdigitalHumans() {
      this.curlGet('/szr/show_2dsample_base').then((res) => {
        // console.log('3D数字人展示请求结果', res)
        if (res.data.code == 1) {
          this.digitalHumanCharacterList = res.data.data
          this.digitalHumanCharacterList.forEach((item, index) => {
            item.checked = false
          })
          console.log('数字人列表', this.digitalHumanCharacterList)
        }

      })
    },
    // 获取2d数字人展示-高级版
    get3DdigitalHumans_2() {
      this.curlGet('/szr/show_2dsample_senior').then((res) => {
        // console.log('3D数字人展示请求结果', res)
        if (res.data.code == 1) {
          this.digitalHumanCharacterList_2 = res.data.data
          this.digitalHumanCharacterList_2.forEach((item, index) => {
            item.checked = false
          })
        }

      })
    },
    // 高级版-获取模版
    getTemplate() {
      this.curlGet('/szr/show_video_template').then((res) => {
        console.log('模版库', res)
        if (res.data.code == 1) {
          this.templateList = res.data.data
          this.templateList.forEach((item, index) => {
            item.checked = false
          })
        }
      })
    },


    // 获取公共音色库
    getPublicSoundLibrary() {
      this.curlGet('/szr/show_audio').then((res) => {
        // console.log('公共音色库请求结果', res)
        if (res.data.code == 1) {
          this.timbreList = res.data.data
          this.timbreList.forEach((item, index) => {
            item.checked = false
          })
          console.log('音色列表', this.timbreList)
        }
      })
    },
    // 生成视频
    submitForm() {
      goAi().then(res => {
        if (res === 'ok') {
          let $user_info = JSON.parse(localStorage.getItem('user_info'))

          // 获取分辨率
          if (this.form.resolution === '') {
            // 未选择分辨率提示
            this.$message({
              message: '请选择分辨率',
              type: 'warning'
            });
            return
          } else {
            let resolutionArr = this.form.resolution.split('x')
            var width = Number(resolutionArr[0])
            var height = Number(resolutionArr[1])
          }

          // 判断XYZ范围是否超出
          let x = Number(this.form.XPosition)
          let y = Number(this.form.YPosition)
          let z = Number(this.form.scaling)
          if (x < -0.5 || x > 0.5) {
            this.$message({
              message: '数字人水平位置（X）超出范围',
              type: 'warning'
            });
            return
          }
          if (y < -1 || y > 1) {
            this.$message({
              message: '数字人垂直位置（Y）超出范围',
              type: 'warning'
            });
            return
          }
          if (z < 0.5 || z > 1.5) {
            this.$message({
              message: '数字人缩放比例（Z）超出范围',
              type: 'warning'
            });
            return
          }

          let data = {}

          // 基础版
          if (this.version === '1') {
            // 没有音频文件 文本驱动
            if (this.form.audioFile === '') {
              if (this.form.timbre === '') {
                this.$message({
                  type: 'warning',
                  message: '请选择音色'
                })
                return
              }
              if (this.form.textarea === '') {
                this.$message({
                  type: 'warning',
                  message: '请录入数字人播报文本'
                })
                return
              }
              if (this.form.figureId === '') {
                this.$message({
                  type: 'warning',
                  message: '请选择数字人人像'
                })
                return
              }
              data = {
                mobile: $user_info.mobile,
                driveType: 1,
                data: {
                  figureId: this.form.figureId,
                  text_content: this.form.textarea,
                  person: this.form.timbre,
                  speed: this.form.speed,
                  volume: this.form.volume,
                  pitch: this.form.intonation,
                  height: height,
                  width: width,
                  x: x,
                  y: y,
                  z: z,
                  backgroundImageUrl: this.form.bgPicFile
                }
              }
            } else {
              //   有音频文件，音频驱动
              data = {
                mobile: $user_info.mobile,
                driveType: 2,
                data: {
                  figureId: this.form.figureId,
                  inputAudioUrl: this.form.audioFile,
                  height: height,
                  width: width,
                  x: x,
                  y: y,
                  z: z,
                  backgroundImageUrl: this.form.bgPicFile
                }
              }
            }

            // console.log('data', data)
            this.isGenerating = true

            this.curlPost('/szr/create_2dsample_base', JSON.stringify(data)).then((res2) => {
              console.log('生成内容请求结果', res2)
              if (res2.data.code === 1) {
                this.$message({
                  message: '生成成功',
                  type: 'success'
                });
                this.result_content = res2.data.data.output_url
              } else {
                this.$message({
                  message: res2.data.msg,
                  type: 'warning'
                });
              }
              this.isGenerating = false
            })
          } else if (this.version === '2') {
            //   高级版

            // 背景图片或背景视频判断
            let materialUrl = ''
            if (this.form.bgPicFile !== '') {
              materialUrl = this.form.bgPicFile
            } else if (this.form.bgVideoFile !== '') {
              materialUrl = this.form.bgVideoFile
            } else {
              materialUrl = ''
            }

            if (this.form.figureId === '' || this.form.templateid === '') {

              this.$message({
                type: 'warning',
                message: '请选择人物'
              })
              return
            }

            // 没有音频文件 文本驱动
            if (this.form.audioFile === '') {
              if (this.form.timbre === '') {
                this.$message({
                  type: 'warning',
                  message: '请选择音色'
                })
                return
              }
              if (this.form.text_content === '') {
                this.$message({
                  type: 'warning',
                  message: '请录入播报文本'
                })
                return
              }

              if (this.form.figureId === '') {
                this.$message({
                  type: 'warning',
                  message: '请选择数字人人像'
                })
                return
              }

              data = {
                mobile: $user_info.mobile,
                driveType: 1,
                data: {
                  figureId: this.form.figureId,
                  templateId: this.form.templateid,
                  text_content: this.form.textarea,
                  title: this.form.title,
                  logoUrl: this.form.videoLogo,
                  bgmUrl: this.form.bgm,
                  materialUrl: materialUrl,
                  person: this.form.timbre,
                  speed: String(this.form.speed),
                  volume: String(this.form.volume),
                  pitch: String(this.form.intonation),
                  height: height,
                  width: width,
                }
              }
            } else {
              //   有音频文件，音频驱动
              data = {
                mobile: $user_info.mobile,
                driveType: 2,
                data: {
                  figureId: this.form.figureId,
                  templateId: this.form.templateid,
                  inputAudioUrl: this.form.audioFile,
                  height: height,
                  width: width,
                  logoUrl: this.form.videoLogo,
                  bgmUrl: this.form.bgm,
                  materialUrl: materialUrl,
                }
              }
            }

            // console.log('data', data)
            this.isGenerating = true

            this.curlPost('/szr/create_2dsample_senior', JSON.stringify(data)).then((res2) => {
              console.log('生成内容请求结果', res2)
              if (res2.data.code === 1) {
                this.$message({
                  message: '生成成功',
                  type: 'success'
                });
                this.result_content = res2.data.data.output_url
              } else {
                this.$message({
                  message: res2.data.msg,
                  type: 'warning'
                });
              }
              this.isGenerating = false
            }).catch(err => {
              console.log(err)
            })
          }


        } else {
          return this.$store.state.loginShow = true
        }
      })
    },
    // 版本切换
    changeVersion() {
      // console.log(111)
      if (this.version === '1') {
        this.version = '2'
      } else if (this.version === '2') {
        this.version = '1'
      }
      this.resetForm()
      this.result_content = ''
      this.isGenerating = false
      this.currentDigitalHumanCharacter = {}
    },
    // 重置参数
    resetForm() {
      this.form = {
        // 内容录入
        textarea: '',
        // 标题
        title: '',
        //   音频文件
        audioFile: '',
        //   分辨率
        resolution: '',
        //   背景图片
        bgPicFile: '',
        // 背景视频
        bgVideoFile: '',
        //   音色
        timbre: '',
        //   语速
        speed: 5,
        //   音量
        volume: 5,
        //   语调
        intonation: 5,
        //   水平位置
        XPosition: '0',
        //   垂直位置
        YPosition: '0',
        //   缩放比例
        scaling: '1',
        // 视频LOGO
        videoLogo: '',
        // 背景音乐
        bgm: '',
        // 数字人模板ID
        templateid: '',
        //   数字人人像ID
        figureId: '',
      }
      this.currentTimbre = {}
    },
    // 获取用户信息
    getUserInfo() {
      let $user_info = JSON.parse(localStorage.getItem('user_info'))
      if ($user_info) {
        this.curlGet('/api/user/info').then((res) => {
          if (res.data.code) {
            this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
            this.headers.token = res.data.data.token
          }
        })
      }
    },

    //   音频文件上传
    // 上传前的验证函数
    beforeUploadAudio(file) {
      const isAllowedType = this.checkFileType_audio(file);
      const isAllowedSize = this.checkFileSize_audio(file);
      return new Promise((resolve, reject) => {
        if (isAllowedType && isAllowedSize) {
          this.checkFileDuration(file)
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                this.$message.error(error);
                reject(false);
              });
        } else {
          if (!isAllowedType) {
            this.$message.error('仅支持 wav、mp3、wma、m4a、aac 格式的音频文件');
          }
          if (!isAllowedSize) {
            this.$message.error('文件大小不能超过 120 MB');
          }
          reject(false);
        }
      });
    },
    // 检查文件类型-音频
    checkFileType_audio(file) {
      console.log(file)
      const allowedTypes = ['audio/wav', 'audio/mp3', 'audio/mpeg', 'audio/wma', 'audio/m4a', 'audio/aac'];
      return allowedTypes.includes(file.type);
    },
    // 检查文件大小-音频
    checkFileSize_audio(file) {
      const maxSize = 120 * 1024 * 1024; // 120 MB
      return file.size <= maxSize;
    },
    // 检查文件时长-音频
    checkFileDuration(file) {
      return new Promise((resolve, reject) => {
        const audio = new Audio(URL.createObjectURL(file));
        audio.addEventListener('loadedmetadata', () => {
          const duration = audio.duration;
          if (duration <= 600) { // 10 分钟 = 600 秒
            resolve();
          } else {
            reject('音频时长不能超过 10 分钟');
          }
          URL.revokeObjectURL(audio.src);
        });
        audio.addEventListener('error', () => {
          reject('无法获取音频时长');
          URL.revokeObjectURL(audio.src);
        });
      });
    },
    // 上传成功的回调函数-音频
    uploadAudioSuccess(res, file, fileList) {
      this.$message.success('音频上传成功');
      // console.log('文件上传成功', res, file, fileList);
      this.form.audioFile = res.data.fullurl
      this.audioFileName = file.name
      // console.log(this.form)
    },
    // 上传失败的回调函数-音频
    uploadAudioError(error, file, fileList) {
      this.$message.error('音频上传失败');
    },
    // 删除已上传的音频文件
    changeAudioFile() {
      this.$confirm('是否删除已上传的音频文件？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form.audioFile = ''
        this.audioFileName = ''
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },


    // 高级版-背景音乐
    // 上传成功的回调函数-音频
    uploadBGMSuccess(res, file, fileList) {
      this.$message.success('音频上传成功');
      // console.log('文件上传成功', res, file, fileList);
      this.form.bgm = res.data.fullurl
      this.bgmFileName = file.name
      // console.log(this.form)
    },
    // 上传失败的回调函数-音频
    uploadBGMError(error, file, fileList) {
      this.$message.error('音频上传失败');
    },
    // 删除已上传的音频文件
    changeBGMFile() {
      this.$confirm('是否删除已上传的音频文件？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form.bgm = ''
        this.bgmFileName = ''
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    //   背景图片上传
    // 上传前的验证函数
    beforeUploadBgPic(file) {
      const isAllowedType = this.checkFileType_pic(file);
      const isAllowedSize = this.checkFileSize_pic(file);
      return new Promise((resolve, reject) => {
        if (isAllowedType && isAllowedSize) {
          resolve(true);
        } else {
          if (!isAllowedType) {
            this.$message.error('仅支持 png、jpg、jpeg、bmp 格式的图片文件');
          }
          if (!isAllowedSize) {
            this.$message.error('文件大小不能超过 3 MB');
          }
          reject(false);
        }
      });
    },
    // 检查文件类型-图片
    checkFileType_pic(file) {
      const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp'];
      return allowedTypes.includes(file.type);
    },
    // 检查文件大小-图片
    checkFileSize_pic(file) {
      const maxSize = 3 * 1024 * 1024; // 3 MB
      return file.size <= maxSize;
    },
    // 上传成功的回调函数-图片
    uploadBgPicSuccess(res, file, fileList) {
      this.$message.success('背景图片上传成功');
      this.form.bgPicFile = res.data.fullurl;
      this.bgPicFileName = file.name;
    },
    // 上传失败的回调函数-图片
    uploadBgPicError(error, file, fileList) {
      this.$message.error('背景图片上传失败');
    },
    // 删除已上传的背景图片文件
    changeBgPicFile() {
      this.$confirm('是否删除已上传的背景图片文件？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form.bgPicFile = ''
        this.bgPicFileName = ''
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    // 背景视频上传
// 上传前的验证函数
    beforeUploadBgVideo(file) {
      const isAllowedType = this.checkFileType_video(file);
      const isAllowedSize = this.checkFileSize_video(file);
      return new Promise((resolve, reject) => {
        if (isAllowedType && isAllowedSize) {
          resolve(true);
        } else {
          if (!isAllowedType) {
            this.$message.error('仅支持 mp4 格式的视频文件');
          }
          if (!isAllowedSize) {
            this.$message.error('文件大小不能超过 500 MB');
          }
          reject(false);
        }
      });
    },
    // 检查文件类型-视频
    checkFileType_video(file) {
      const allowedTypes = ['video/mp4'];
      return allowedTypes.includes(file.type);
    },
    // 检查文件大小-视频
    checkFileSize_video(file) {
      const maxSize = 500 * 1024 * 1024; // 500 MB
      return file.size <= maxSize;
    },
    // 上传成功的回调函数-视频
    uploadBgVideoSuccess(res, file, fileList) {
      this.$message.success('背景视频上传成功');
      this.form.bgVideoFile = res.data.fullurl;
      this.bgVideoFileName = file.name;
    },
    // 上传失败的回调函数-视频
    uploadBgVideoError(error, file, fileList) {
      this.$message.error('背景视频上传失败');
    },
    // 删除已上传的背景视频文件
    changeBgVideoFile() {
      this.$confirm('是否删除已上传的背景视频文件？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form.bgVideoFile = ''
        this.bgVideoFileName = ''
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    //   获取 背景是否透明 参数
    getIsBgTransparent() {
      // console.log(this.form.isBgTransparent)
      if (this.form.isBgTransparent === '1') {
        if (this.form.bgPicFile !== '' || this.bgPicFileName !== '') {
          this.form.bgPicFile = ''
          this.bgPicFileName = ''
          this.$message({
            message: '已删除背景图片文件',
            type: 'warning'
          });
        }
        if (this.form.bgVideoFile !== '' || this.bgVideoFileName !== '') {
          this.form.bgVideoFile = ''
          this.bgVideoFileName = ''
          this.$message({
            message: '已删除背景视频文件',
            type: 'warning'
          });
        }
      }
    },


    //   视频LOGO图片上传
    // 上传前的验证函数
    beforeUploadVideoLogo(file) {
      const isAllowedType = this.checkFileType_videoLogo(file);
      const isAllowedSize = this.checkFileSize_videoLogo(file);
      return new Promise((resolve, reject) => {
        if (isAllowedType && isAllowedSize) {
          resolve(true);
        } else {
          if (!isAllowedType) {
            this.$message.error('仅支持 png、jpg、jpeg、bmp 格式的图片文件');
          }
          if (!isAllowedSize) {
            this.$message.error('文件大小不能超过 3 MB');
          }
          reject(false);
        }
      });
    },
    // 检查文件类型-图片
    checkFileType_videoLogo(file) {
      const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp'];
      return allowedTypes.includes(file.type);
    },
    // 检查文件大小-图片
    checkFileSize_videoLogo(file) {
      const maxSize = 3 * 1024 * 1024; // 3 MB
      return file.size <= maxSize;
    },
    // 上传成功的回调函数-图片
    uploadVideoLogoSuccess(res, file, fileList) {
      this.$message.success('视频LOGO上传成功');
      this.form.videoLogo = res.data.fullurl;
      this.videoLogoFileName = file.name;
    },
    // 上传失败的回调函数-图片
    uploadVideoLogoError(error, file, fileList) {
      this.$message.error('视频LOGO上传失败');
    },
    // 删除已上传的LOGO图片文件
    changeVideoLogoFile() {
      this.$confirm('是否删除已上传的视频LOGO？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form.videoLogo = ''
        this.videoLogoFileName = ''
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai';
</style>